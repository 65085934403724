import React from 'react'
import PropTypes from 'prop-types'
import { TextField } from '@material-ui/core'

const FormInput = ({
  form: { handleChange, handleBlur },
  label,
  placeholder,
  shrink,
  ...props
}) => {
  return (
    <TextField
      label={label}
      placeholder={placeholder}
      fullWidth
      variant="outlined"
      onChange={handleChange}
      onBlur={handleBlur}
      InputLabelProps={{
        shrink,
      }}
      inputProps={{ autoComplete: 'off' }}
      {...props}
    />
  )
}

FormInput.propTypes = {
  form: PropTypes.object.isRequired,
}

export default FormInput
