import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { TextField } from '@material-ui/core'
import { matchSorter } from 'match-sorter'
import { Autocomplete } from '@material-ui/lab'

const ClassroomAutocomplete = ({
  options,
  label,
  handleChange,
  handleClose,
  arrayHelpers: { form, unshift },
  classrooms = [],
  optionSelected,
  setOptionSelected,
  isEditing,
  showLoading,
  hideLoading,
  error,
  helperText,
  disabled,
}) => {
  const [currentList, setCurrentList] = useState([])

  useEffect(() => {
    const getCurrentList = () => {
      return options
        .filter(
          classroom => !classrooms.find(selectedClassroom => classroom.id === selectedClassroom.id),
        )
        .sort((a, b) => {
          if (a.name < b.name) return -1
          if (a.name > b.name) return 1
          return 0
        })
    }

    const listWithOutSelected = getCurrentList()
    setCurrentList(listWithOutSelected)
  }, [options, classrooms, optionSelected])

  const filterOptions = (opt, { inputValue }) =>
    matchSorter(opt, inputValue, {
      keys: [
        { threshold: matchSorter.rankings.CONTAINS, key: 'name' },
        { threshold: matchSorter.rankings.CONTAINS, key: 'school' },
      ],
    })

  const handleClassroom = value => {
    if (!value && typeof handleClose === 'function') handleClose()

    setOptionSelected(value)

    if (value && value.id) {
      const selectedClassrooms = classrooms.filter(classroom => classroom.id === value.id)

      if (selectedClassrooms.length === 0) unshift(value)
      if (typeof handleChange === 'function') handleChange(value)
    }
  }

  const handleClassroomChange = async (event, selected) => {
    if (selected && selected.value === 'select-all') {
      showLoading()

      setTimeout(() => {
        form.setValues({
          cities: form.values.cities,
          name: form.values.name,
          number: form.values.number,
          schools: form.values.schools,
          state: form.values.state,

          regional: form.values.regional,
          rows: form.values.rows,

          competencies: form.values.competencies,
          description: form.values.description,
          grades: form.values.grades,
          regionals: form.values.regionals,
          schoolYear: form.values.schoolYear,

          userId: form.values.userId,
          email: form.values.email,
          cpf: form.values.cpf,

          status: form.values.status,
          initialDate: form.values.initialDate,
          finalDate: form.values.finalDate,
          startDate: form.values.startDate,
          endDate: form.values.endDate,
          classrooms: [
            ...classrooms,
            ...currentList.filter(classroom => classroom.value !== 'select-all'),
          ],

          year: form.values.year,
          unityType: form.values.unityType,
          unitType: form.values.unitType,
          unity: form.values.unity,
          partner: form.values.partner,
        })
        hideLoading()
      })
    }
    return handleClassroom(selected)
  }

  return (
    <Autocomplete
      disabled={(isEditing && isEditing === true) || disabled}
      disableCloseOnSelect
      disableListWrap
      options={currentList}
      getOptionLabel={option => {
        if (option) {
          if (option.name && option.school && option.school.name)
            return `${option.school.name} - ${option.name}`
          if (option.name && !option.school) return `${option.name}`
        }
        return null
      }}
      onKeyDown={() => {}}
      filterOptions={(opt, params) => {
        const filtered = filterOptions(opt, params)
        if (options.length > 0 && currentList.length > 0) {
          return [{ name: 'SELECIONAR TODAS', value: 'select-all' }, ...filtered]
        }
        return [...filtered]
      }}
      onChange={(event, value) => handleClassroomChange(event, value)}
      noOptionsText="Nenhuma opção disponível"
      value={optionSelected}
      renderInput={params => (
        <TextField
          {...params}
          error={error}
          helperText={helperText}
          label={label}
          variant="outlined"
          fullWidth
        />
      )}
    />
  )
}

ClassroomAutocomplete.propTypes = {
  arrayHelpers: PropTypes.object.isRequired,
  classrooms: PropTypes.array.isRequired,
  error: PropTypes.bool,
  handleChange: PropTypes.func,
  handleClose: PropTypes.func,
  handleReset: PropTypes.func,
  label: PropTypes.string.isRequired,
  options: PropTypes.array,
}

ClassroomAutocomplete.defaultProps = {
  handleChange: () => {},
  handleClose: () => {},
  handleReset: () => {},
  options: [],
  error: false,
}

export default React.memo(ClassroomAutocomplete)
