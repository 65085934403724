import React, { useCallback, useContext, useEffect } from 'react'
import { Route } from 'react-router-dom'
import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import UserService from '../services/UserService'
import { HeaderContext } from '../contexts'

const RouteWrapper = ({ component, isPrivate, ...rest }) => {
  const getLocationIdpHint = () => {
    const searchParams = new URLSearchParams(window.location.search)
    if (searchParams && searchParams.has('idpHint')) {
      return searchParams.get('idpHint')
    }

    return 0
  }
  const location = useLocation()
  const { setCurrentPath } = useContext(HeaderContext)

  useEffect(() => {
    setCurrentPath(location.pathname)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  const login = useCallback(async () => {
    const idpHint = getLocationIdpHint()
    if (idpHint !== 0) {
      if (idpHint === 'lex') {
        await UserService.doLoginLex()
      } else if (idpHint === 'lti-ftd') {
        await UserService.doLoginFTD()
      } else {
        console.log('invalid ipd parameter')
      }
    } else {
      await UserService.doLogin()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!UserService.isLoggedIn() && isPrivate) {
    login()
  }

  return <Route {...rest} component={component} />
}

RouteWrapper.propTypes = {
  component: PropTypes.any.isRequired,
  isPrivate: PropTypes.bool,
}

RouteWrapper.defaultProps = {
  isPrivate: false,
}

export default RouteWrapper
