import React, { createContext, useState } from 'react'
import PropTypes from 'prop-types'

export const FtdContext = createContext()

export const FtdProvider = ({ children }) => {
  const [selectedRowData, setSelectedRowData] = useState(null)

  return (
    <FtdContext.Provider value={{ selectedRowData, setSelectedRowData }}>
      {children}
    </FtdContext.Provider>
  )
}

export default FtdProvider

FtdProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
}
