import React, { createContext, useState } from 'react'

export const ImportTrackingContext = createContext({
  city: null,
  setCity: () => {},
  school: null,
  setSchool: () => {},
  frequencyId: null,
  setFrequencyId: () => {},
  frequencyItemId: null,
  setFrequencyItemId: () => {},
  program: null,
  setProgram: () => {},
  year: null,
  setYear: () => {},
})

export const ImportTrackingProvider = ({ children }) => {
  const [city, _setCity] = useState({})
  const [school, _setSchool] = useState({})
  const [frequencyId, _setFrequencyId] = useState({})
  const [frequencyItemId, _setFrequencyItemId] = useState({})
  const [program, _setProgram] = useState({})
  const [year, _setYear] = useState({})

  const setCity = value => {
    _setCity(value)
  }

  const setSchool = value => {
    _setSchool(value)
  }

  const setFrequencyId = value => {
    _setFrequencyId(value)
  }

  const setFrequencyItemId = value => {
    _setFrequencyItemId(value)
  }

  const setProgram = value => {
    _setProgram(value)
  }

  const setYear = value => {
    _setYear(value)
  }

  return (
    <ImportTrackingContext.Provider
      value={{
        city,
        setCity,
        school,
        setSchool,
        frequencyId,
        setFrequencyId,
        frequencyItemId,
        setFrequencyItemId,
        program,
        setProgram,
        year,
        setYear,
      }}
    >
      {children}
    </ImportTrackingContext.Provider>
  )
}

export default ImportTrackingProvider
