const DefaultBreadcrumb = () => ({
  list: {
    position: 'relative',
    zIndex: 10,
    fontSize: '13px',
  },
  itemLink: {
    color: '#00A8E9',
    textDecoration: 'none',
  },
  itemText: {
    color: '#A6A6A6',
    '&:hover': {
      textDecoration: 'none',
    },
  },
})

const MakingChoicesBreadcrumb = () => ({
  list: {
    position: 'relative',
    zIndex: 10,
    fontSize: '14px',
    fontFamily: 'Barlow',
    fontWeight: '700',
    lineHeight: '150%',
    fontStyle: 'normal',
  },
  itemLink: {
    color: '#CBD2E0',
    textDecoration: 'none',
  },
  itemText: {
    color: '#003C78',
    '&:hover': {
      textDecoration: 'none',
    },
  },
})

const customBreadcrumb = () => ({
  list: {
    position: 'relative',
    zIndex: 10,
    fontSize: '14px',
    fontFamily: 'Barlow',
    fontWeight: '400',
    lineHeight: '150%',
    fontStyle: 'normal',
  },
  itemLink: {
    color: '#858585',
    '&:hover': {
      color: '#00A8E9',
    },
  },
  itemText: {
    color: '#003C78',
    fontWeight: '600',
    textDecoration: 'underline',
    '&:hover': {
      textDecoration: 'none',
    },
  },
})

export { DefaultBreadcrumb, MakingChoicesBreadcrumb, customBreadcrumb }
