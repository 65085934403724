import React, { createContext, useState } from 'react'
import PropTypes from 'prop-types'

export const RegionalContext = createContext({
  id: null,
  setId: () => {},
  schools: [],
  setSchools: () => {},
  state: {},
  setState: () => {},
  cities: [],
  setCities: () => {},
  number: null,
  setNumber: () => {},
  name: null,
  setName: () => {},
  type: null,
  setType: () => {},
  isEditing: null,
  setIsEditing: () => {},
  isCollectingGender: true,
  setIsCollectingGender: () => {},
  active: true,
  setIsActive: () => {},
})

export default function RegionalProvider({ children }) {
  const [id, _setId] = useState([])
  const [schools, _setSchools] = useState([])
  const [state, _setState] = useState([])
  const [cities, _setCities] = useState([])
  const [number, _setNumber] = useState(null)
  const [name, _setName] = useState(null)
  const [type, _setType] = useState(null)
  const [active, _setActive] = useState(true)
  const [isEditing, _setIsEditing] = useState(null)
  const [isCollectingGender, _setIsCollectingGender] = useState(true)

  const setId = value => {
    _setId(value)
  }

  const setSchools = value => {
    _setSchools(value)
  }

  const setState = value => {
    _setState(value)
  }

  const setCities = value => {
    _setCities(value)
  }

  const setNumber = value => {
    _setNumber(value)
  }

  const setName = value => {
    _setName(value)
  }

  const setType = value => {
    _setType(value)
  }

  const setIsEditing = value => {
    _setIsEditing(value)
  }

  const setIsCollectingGender = value => {
    _setIsCollectingGender(value)
  }

  const setActive = value => {
    _setActive(value)
  }

  return (
    <RegionalContext.Provider
      value={{
        id,
        setId,
        schools,
        setSchools,
        state,
        setState,
        cities,
        setCities,
        number,
        setNumber,
        name,
        setName,
        type,
        setType,
        isEditing,
        setIsEditing,
        isCollectingGender,
        setIsCollectingGender,
        active,
        setActive,
      }}
    >
      {children}
    </RegionalContext.Provider>
  )
}

RegionalProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
}
