import React, { useContext, Suspense, lazy } from 'react'
import { Redirect, Switch, withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import Route from './Route'
import RouteLoader from '../utils/RouteLoader'
import AuthContext from '../contexts/AuthContext'
import ROLES from '../shared/profileRules'
import { getSelectedProfile } from '../utils/user/profileHelper'
import PartnershipCollaboratorAssessment from '../pages/PartnershipCollaboratorAssessment'

const ProfileSelection = withRouter(lazy(() => import('../pages/ProfileSelection')))
const RequestAccess = withRouter(lazy(() => import('../pages/RequestAccess/Teacher')))
const RequestAccessTeacherMultiple = withRouter(
  lazy(() => import('../pages/RequestAccess/TeacherMultiple')),
)
const RequestAccessManager = withRouter(lazy(() => import('../pages/RequestAccess/Manager')))
const ManagerEdit = withRouter(lazy(() => import('../pages/RequestAccess/Manager/ManagerEdit')))
const Dashboard = withRouter(lazy(() => import('../pages/Dashboard')))
const PartnerList = withRouter(lazy(() => import('../pages/PartnerList')))
const TeacherList = withRouter(lazy(() => import('../pages/TeacherList')))
const ClassRoomList = withRouter(lazy(() => import('../pages/ClassRoomList')))
const Password = withRouter(lazy(() => import('../pages/Password')))
const Application = withRouter(lazy(() => import('../pages/Application')))
const ApplicationEdit = withRouter(lazy(() => import('../pages/Application/ApplicationEdit')))
const ApplicationList = withRouter(lazy(() => import('../pages/ApplicationList')))
const DialoguesApplication = withRouter(lazy(() => import('../pages/Application/Dialogues')))
const DialoguesApplicationEdit = withRouter(
  lazy(() => import('../pages/Application/Dialogues/DialouguesApplicationEdit')),
)

const ClassroomManager = withRouter(lazy(() => import('../pages/ClassRoom/ClassroomManager')))

const RequestAccessNetworkManager = withRouter(
  lazy(() => import('../pages/RequestAccess/NetworkManager')),
)
const RequestAccessResearcher = withRouter(lazy(() => import('../pages/RequestAccess/Researcher')))
const Regional2 = withRouter(lazy(() => import('../pages/Regional2')))
const Development = withRouter(lazy(() => import('../pages/Development')))
const Student = withRouter(lazy(() => import('../pages/Student')))
const StudentList = withRouter(lazy(() => import('../pages/StudentList')))
const ProgramList = withRouter(lazy(() => import('../pages/ProgramList')))
const SchoolManagerList = withRouter(lazy(() => import('../pages/SchoolManagerList')))
const Training = withRouter(lazy(() => import('../pages/Training')))
const WidgetLMS = withRouter(lazy(() => import('../pages/WidgetLMS')))
const PageNotFound = withRouter(lazy(() => import('../pages/PageNotFound')))
const ImportData = withRouter(lazy(() => import('../pages/ImportData')))
const ImportRegistrationData = withRouter(lazy(() => import('../pages/ImportRegistrationData')))
const ImportPartnershipCollaboratorData = withRouter(
  lazy(() => import('../pages/ImportPartnershipCollaboratorData')),
)
const ImportTrackingData = withRouter(lazy(() => import('../pages/ImportTrackingData')))
const RegionalList = withRouter(lazy(() => import('../pages/RegionalList')))
const RegionalCoordinator = withRouter(lazy(() => import('../pages/RegionalCoordinator')))
const RegionalCoordinatorList = withRouter(lazy(() => import('../pages/RegionalCoordinatorList')))
const RegionalCoordinatorEdit = withRouter(
  lazy(() => import('../pages/RegionalCoordinator/RegionalCoordinatorEdit')),
)
const PartnershipCollaborator = withRouter(lazy(() => import('../pages/PartnershipCollaborator')))
const Accompaniment = withRouter(lazy(() => import('../pages/Accompaniment')))
const AccompanimentList = withRouter(lazy(() => import('../pages/AccompanimentList')))
const AccompanimentView = withRouter(lazy(() => import('../pages/Accompaniment/AccompanimentView')))
const Collection = withRouter(lazy(() => import('../pages/Collection')))
const TermsPage = withRouter(lazy(() => import('../pages/Terms')))
const PoliticsPage = withRouter(lazy(() => import('../pages/Politics')))
const Reports = withRouter(lazy(() => import('../pages/Reports')))
const NotebookList = withRouter(lazy(() => import('../pages/NotebookList')))
const Notebook = withRouter(lazy(() => import('../pages/Notebook')))
const NotebookView = withRouter(lazy(() => import('../pages/Notebook/notebookView')))
const NotebookItemList = withRouter(lazy(() => import('../pages/NotebookItemList')))
const NotebookItem = withRouter(lazy(() => import('../pages/NotebookItem')))
const InstrumentList = withRouter(lazy(() => import('../pages/InstrumentList')))
const Instrument = withRouter(lazy(() => import('../pages/Instrument')))
const AnswerKey = withRouter(lazy(() => import('../pages/AnswerKey')))
const AnswerKeyList = withRouter(lazy(() => import('../pages/AnswerKeyList')))
const NotebookToolList = withRouter(lazy(() => import('../pages/NotebookToolList')))
const NotebookTool = withRouter(lazy(() => import('../pages/NotebookTool')))
const WorkWorldTool = withRouter(lazy(() => import('../pages/Application/WorkWorldTool')))
const ResearcherAssessmentList = withRouter(lazy(() => import('../pages/ResearcherAssessmentList')))
const ResearcherAssessment = withRouter(lazy(() => import('../pages/ResearcherAssessment')))
const ResearcherAssessmentView = withRouter(lazy(() => import('../pages/ResearcherAssessmentView')))
const ReportLibrary = withRouter(lazy(() => import('../pages/ReportLibrary')))
const ReportIntegrationList = withRouter(lazy(() => import('../pages/ReportIntegrationList')))
const ManualIntegration = withRouter(lazy(() => import('../pages/ManualIntegration')))
const ApplicationStudentList = withRouter(lazy(() => import('../pages/ApplicationStudentList')))
const ScoreAbsence = withRouter(lazy(() => import('../pages/ScoreAbsence')))
const PartnershipCollaboratorAssessmentList = withRouter(
  lazy(() => import('../pages/PartnershipCollaboratorAssessmentList')),
)
const Partnership = withRouter(lazy(() => import('../pages/Partnership')))
const ResearcherInstructionAndWelcome = withRouter(
  lazy(() => import('../components/ResearcherInstructionAndWelcome')),
)
const PartnershipList = withRouter(lazy(() => import('../pages/PartnershipList')))
const MakingChoices = withRouter(lazy(() => import('../pages/MakingChoices')))
const SennaKids = withRouter(lazy(() => import('../pages/Application/SennaKids')))
const ManualImportTrackingData = withRouter(lazy(() => import('../pages/ManualImportTrackingData')))
const ManualImportTrackingDataFormQuestion = withRouter(
  lazy(() => import('../pages/ManualImportTrackingData/question/ManualImportTrackingDataQuestion')),
)
const SennaKidsCollection = withRouter(
  lazy(() => import('../pages/SennaKidsCollection/SennaKidsCollection')),
)
const SennaAdult = withRouter(lazy(() => import('../pages/Application/SennaAdult')))
const SennaAdultCollection = withRouter(
  lazy(() => import('../pages/SennaAdultCollection/SennaAdultCollection')),
)
const SennaAdultsInstrument = withRouter(lazy(() => import('../pages/SennaAdultosInstrument')))
const UnityRegister = withRouter(lazy(() => import('../pages/UnityRegister')))
const UnitList = withRouter(lazy(() => import('../pages/UnitList')))
const RegisterAuto = withRouter(lazy(() => import('../pages/RegisterAuto')))
const StudentBatchEdit = withRouter(lazy(() => import('../pages/Student/StudentBatchEdit')))
const BatchExaminee = withRouter(
  lazy(() => import('../pages/BatchRegistration/pages/BatchExaminee')),
)
const Administrator = withRouter(lazy(() => import('../pages/Administrator')))
const AdministratorFtd = withRouter(lazy(() => import('../pages/AdministratorFtd')))
const AccessLog = withRouter(lazy(() => import('../pages/AccessLog')))

const RoutePrivateAccess = ({ accessRoles, component, path }) => {
  const { currentUser } = useContext(AuthContext)

  // eslint-disable-next-line consistent-return
  const handleRules = menuRules => {
    if (getSelectedProfile()) {
      return menuRules.includes(getSelectedProfile().id)
    }
  }

  const handleAccessRoles = handleRules(accessRoles)

  return (
    <>
      {currentUser ? (
        <>
          {handleAccessRoles ? (
            <Route exact path={path} component={component} isPrivate />
          ) : (
            <Redirect to="/dashboard" />
          )}
        </>
      ) : (
        <>
          <Route exact path={path} component={component} isPrivate />
        </>
      )}
    </>
  )
}

RoutePrivateAccess.propTypes = {
  accessRoles: PropTypes.array.isRequired,
  component: PropTypes.any.isRequired,
  path: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
}

export default function Routes() {
  return (
    <Suspense fallback={<RouteLoader />}>
      <Switch>
        <Route path="/profile" exact component={ProfileSelection} isPrivate />
        <RoutePrivateAccess
          accessRoles={[...ROLES.NETWORK_MANAGER, ...ROLES.RESEARCHER]}
          path="/importar" // Tela não é mais usada
          exact
          component={ImportData}
        />
        <RoutePrivateAccess
          accessRoles={[...ROLES.NETWORK_MANAGER, ...ROLES.RESEARCHER]}
          path="/importar-dados-cadastrais-network-manager"
          exact
          component={ImportRegistrationData}
        />
        <RoutePrivateAccess
          accessRoles={[...ROLES.NETWORK_MANAGER, ...ROLES.RESEARCHER]}
          path="/importar-dados-cadastrais-examinee"
          exact
          component={BatchExaminee}
        />
        <RoutePrivateAccess
          accessRoles={[...ROLES.NETWORK_MANAGER, ...ROLES.RESEARCHER]}
          path={[
            '/importar-dados-cadastrais-examinee/historico',
            '/importar-dados-cadastrais-examinee/historico/:id',
          ]}
          exact
          component={BatchExaminee}
        />
        <RoutePrivateAccess
          accessRoles={[...ROLES.NETWORK_MANAGER, ...ROLES.RESEARCHER]}
          path="/importar-dados-cadastrais-classroom"
          exact
          component={ImportRegistrationData}
        />
        <RoutePrivateAccess
          accessRoles={[...ROLES.NETWORK_MANAGER, ...ROLES.RESEARCHER]}
          path="/importar-dados-cadastrais-teacher"
          exact
          component={ImportRegistrationData}
        />
        <RoutePrivateAccess
          accessRoles={ROLES.NETWORK_MANAGER}
          path="/importar-dados-acompanhamento"
          exact
          component={ImportTrackingData}
        />
        <RoutePrivateAccess
          accessRoles={ROLES.NETWORK_MANAGER}
          path="/importar-dados-acompanhamento-manual"
          exact
          component={ManualImportTrackingData}
        />
        <RoutePrivateAccess
          accessRoles={ROLES.NETWORK_MANAGER}
          path="/dados-acompanhamento-manual"
          exact
          component={ManualImportTrackingDataFormQuestion}
        />
        <RoutePrivateAccess
          accessRoles={ROLES.RESEARCHER}
          path="/importar-respondente"
          exact
          component={ImportPartnershipCollaboratorData}
        />
        <Route path="/dashboard" component={Dashboard} isPrivate />
        <Route exact path="/cadastro-professor" component={RequestAccess} />

        <RoutePrivateAccess
          accessRoles={ROLES.TEACHER}
          path="/perfil-professor/:id"
          component={RequestAccess}
        />
        <RoutePrivateAccess
          accessRoles={ROLES.NETWORK_MANAGER}
          path="/professor/:id"
          component={RequestAccess}
        />
        <RoutePrivateAccess
          accessRoles={ROLES.NETWORK_MANAGER}
          path="/professor-lote"
          component={RequestAccessTeacherMultiple}
        />
        <RoutePrivateAccess
          accessRoles={ROLES.NETWORK_MANAGER}
          path="/cadastro/professor"
          component={RequestAccess}
        />
        <Route
          exact
          accessRoles={ROLES.NETWORK_MANAGER}
          path="/cadastro/gestor"
          component={RequestAccessManager}
        />
        <Route exact path="/cadastro/gestor-rede" component={RequestAccessNetworkManager} />
        <Route exact path="/cadastro/pesquisador" component={RequestAccessResearcher} />
        <RoutePrivateAccess
          accessRoles={ROLES.SCHOOL_MANAGER}
          path="/adm/cadastro/gestor/"
          component={ManagerEdit}
        />
        <RoutePrivateAccess
          accessRoles={ROLES.SCHOOL_MANAGER}
          path="/cadastro/gestor/:id"
          component={ManagerEdit}
        />
        <RoutePrivateAccess accessRoles={ROLES.ADMIN} path="/parcerias" component={PartnerList} />
        <Route path="/primeiro-acesso/:token" component={Password} exact />
        <RoutePrivateAccess
          accessRoles={ROLES.NETWORK_MANAGER}
          path="/editar-aplicacao-s/:id"
          component={ApplicationEdit}
        />
        <RoutePrivateAccess
          accessRoles={ROLES.NETWORK_MANAGER}
          path="/cadastrar-aplicacao"
          component={Application}
        />
        <RoutePrivateAccess
          accessRoles={ROLES.NETWORK_MANAGER}
          path="/editar-aplicacao-d/:id"
          component={DialoguesApplicationEdit}
        />
        <RoutePrivateAccess
          accessRoles={ROLES.NETWORK_MANAGER}
          path="/cadastrar-aplicacao-dialogos"
          component={DialoguesApplication}
        />
        <RoutePrivateAccess
          accessRoles={ROLES.ALL}
          path="/aplicacoes"
          component={ApplicationList}
        />
        <RoutePrivateAccess
          accessRoles={[...ROLES.EXAMINEE]}
          path="/aplicacoes-estudante"
          component={ApplicationStudentList}
        />
        <RoutePrivateAccess
          accessRoles={[...ROLES.RESPONDER]}
          path="/aplicacoes-respondente-sa"
          component={ApplicationStudentList}
        />

        <RoutePrivateAccess
          accessRoles={ROLES.SCHOOL_MANAGER}
          path="/gestor/turmas/cadastrar/"
          component={ClassroomManager}
        />
        <RoutePrivateAccess
          accessRoles={ROLES.SCHOOL_MANAGER}
          path="/gestor/turmas/:id"
          component={ClassroomManager}
        />
        <RoutePrivateAccess accessRoles={ROLES.TEACHER} path="/turmas" component={ClassRoomList} />
        <RoutePrivateAccess
          accessRoles={ROLES.TEACHER}
          path="/estudantes/cadastrar/:id"
          component={Student}
        />
        <Route
          accessRoles={ROLES.NETWORK_MANAGER}
          path="/estudantes/cadastrar"
          component={Student}
        />
        <Route
          accessRoles={ROLES.NETWORK_MANAGER}
          path="/estudantes/editar"
          component={StudentBatchEdit}
        />
        <RoutePrivateAccess
          accessRoles={ROLES.TEACHER}
          path="/estudantes"
          component={StudentList}
        />
        <RoutePrivateAccess
          accessRoles={ROLES.NETWORK_MANAGER}
          path="/programas"
          component={ProgramList}
        />
        <RoutePrivateAccess
          accessRoles={ROLES.NETWORK_MANAGER}
          path="/estudante/nota-falta/:id"
          component={ScoreAbsence}
        />
        <RoutePrivateAccess
          accessRoles={ROLES.SCHOOL_MANAGER}
          path="/professores"
          component={TeacherList}
        />
        <RoutePrivateAccess
          accessRoles={ROLES.NETWORK_MANAGER}
          path="/gestor-escolar"
          component={SchoolManagerList}
        />
        <RoutePrivateAccess
          accessRoles={ROLES.NETWORK_MANAGER}
          exact
          path="/regionais"
          component={RegionalList}
        />
        <RoutePrivateAccess
          exact
          accessRoles={ROLES.NETWORK_MANAGER}
          path={['/regionais/cadastrar', '/regionais/:id']}
          component={Regional2}
        />
        <RoutePrivateAccess
          accessRoles={ROLES.NETWORK_MANAGER}
          exact
          path="/coordenadores"
          component={RegionalCoordinatorList}
        />
        <RoutePrivateAccess
          accessRoles={ROLES.NETWORK_MANAGER}
          path="/coordenadores/cadastrar"
          component={RegionalCoordinator}
        />
        <RoutePrivateAccess
          accessRoles={ROLES.NETWORK_MANAGER}
          path="/coordenadores/:id"
          component={RegionalCoordinatorEdit}
        />
        <RoutePrivateAccess
          accessRoles={ROLES.RESEARCHER}
          path="/respondentes/cadastrar"
          component={PartnershipCollaborator}
        />
        <RoutePrivateAccess
          accessRoles={ROLES.REPORTS_ACCESS}
          path="/relatorios"
          component={Reports}
        />
        <RoutePrivateAccess
          accessRoles={[
            ...ROLES.TEACHER,
            ...ROLES.EXAMINEE,
            ...ROLES.PARTNERSHIP_COLLABORATOR,
            ...ROLES.REGIONAL_COORDINATOR,
            ...ROLES.EXTERNAL_COORDINATOR,
            ...ROLES.DIRECTOR,
            ...ROLES.EDUCATOR_TECHNICIAN,
          ]}
          path="/biblioteca-relatorios"
          component={ReportLibrary}
        />
        <RoutePrivateAccess
          accessRoles={[...ROLES.ADMIN, ...ROLES.NETWORK_MANAGER]}
          path="/integracao-manual"
          component={ManualIntegration}
        />
        <RoutePrivateAccess
          accessRoles={[...ROLES.ADMIN, ...ROLES.NETWORK_MANAGER]}
          path="/relatorios-integracao"
          component={ReportIntegrationList}
        />
        <RoutePrivateAccess
          accessRoles={ROLES.REGIONAL_COORDINATOR}
          exact
          path="/acompanhamentos"
          component={AccompanimentList}
        />
        <RoutePrivateAccess
          accessRoles={ROLES.REGIONAL_COORDINATOR}
          path="/acompanhamentos/registrar"
          component={Accompaniment}
        />
        <RoutePrivateAccess
          accessRoles={ROLES.REGIONAL_COORDINATOR}
          path="/acompanhamentos/:id"
          component={AccompanimentView}
        />
        <RoutePrivateAccess
          accessRoles={ROLES.RESEARCHER}
          exact
          path="/instrumentos"
          component={NotebookToolList}
        />
        <RoutePrivateAccess
          accessRoles={ROLES.RESEARCHER}
          exact
          path="/instrumentos/cadastrar"
          component={NotebookTool}
        />
        <RoutePrivateAccess
          accessRoles={ROLES.RESEARCHER}
          exact
          path="/instrumentos/:id"
          component={NotebookTool}
        />
        <RoutePrivateAccess
          accessRoles={ROLES.RESEARCHER}
          exact
          path="/cadernos"
          component={NotebookList}
        />
        <RoutePrivateAccess
          accessRoles={ROLES.RESEARCHER}
          path="/cadernos/cadastrar"
          component={Notebook}
        />
        <RoutePrivateAccess
          exact
          accessRoles={ROLES.RESEARCHER}
          path="/itens"
          component={NotebookItemList}
        />
        <RoutePrivateAccess
          exact
          accessRoles={ROLES.RESEARCHER}
          path="/cadastrar-item"
          component={NotebookItem}
        />
        <RoutePrivateAccess
          exact
          accessRoles={ROLES.RESEARCHER}
          path="/editar-item/:id"
          component={NotebookItem}
        />
        <RoutePrivateAccess
          accessRoles={ROLES.EDUCATOR_INSTRUMENT}
          path="/instrumento/registrar"
          exact
          component={Instrument}
        />
        <RoutePrivateAccess
          accessRoles={ROLES.EDUCATOR_INSTRUMENT}
          path="/instrumentoContent/registrar"
          exact
          component={Instrument}
        />
        <RoutePrivateAccess accessRoles={ROLES.ALL} path="/coletas/:id" component={Collection} />
        <Route path="/termos" component={TermsPage} />
        <Route path="/lms" component={WidgetLMS} />
        <Route path="/em-desenvolvimento" component={Development} />
        <Route path="/treinamento" component={Training} />
        <Route path="/politicas" component={PoliticsPage} />
        <RoutePrivateAccess
          exact
          path="/instrumento"
          accessRoles={ROLES.EDUCATOR_INSTRUMENT}
          component={InstrumentList}
        />
        <RoutePrivateAccess
          exact
          accessRoles={ROLES.EDUCATOR_INSTRUMENT}
          path="/instrumentoContent"
          component={InstrumentList}
        />
        <RoutePrivateAccess
          accessRoles={[...ROLES.PARTNERSHIP_COLLABORATOR, ...ROLES.EXAMINEE]}
          path="/aplicacoes-respondente/registrar/:id"
          exact
          component={PartnershipCollaboratorAssessment}
        />
        <RoutePrivateAccess
          accessRoles={[...ROLES.PARTNERSHIP_COLLABORATOR, ...ROLES.EXAMINEE]}
          path="/aplicacoes-respondente/registrar/:id"
          component={PartnershipCollaboratorAssessment}
        />
        <RoutePrivateAccess
          accessRoles={ROLES.EDUCATOR_INSTRUMENT}
          path="/instrumento/registrar/:id"
          exact
          component={Instrument}
        />
        <RoutePrivateAccess
          accessRoles={ROLES.EDUCATOR_INSTRUMENT}
          path="/instrumentoContent/registrar/:id"
          component={Instrument}
        />
        <RoutePrivateAccess
          accessRoles={ROLES.RESEARCHER}
          exact
          path="/chave-resposta/registrar"
          component={AnswerKey}
        />
        <RoutePrivateAccess
          accessRoles={ROLES.RESEARCHER}
          exact
          path="/chave-resposta/:id"
          component={AnswerKey}
        />
        <RoutePrivateAccess
          exact
          accessRoles={ROLES.RESEARCHER}
          path="/chave-resposta"
          component={AnswerKeyList}
        />
        <Redirect exact path="/" to="/dashboard" />
        <RoutePrivateAccess
          accessRoles={ROLES.RESEARCHER}
          exact
          path="/cadernos/:id"
          component={Notebook}
        />
        <RoutePrivateAccess
          accessRoles={ROLES.RESEARCHER}
          exact
          path="/cadernos/detalhes/:id"
          component={NotebookView}
        />
        <RoutePrivateAccess
          accessRoles={ROLES.NETWORK_MANAGER}
          path="/cadastrar-aplicacao-mundo-trabalho"
          component={WorkWorldTool}
        />
        <RoutePrivateAccess
          accessRoles={ROLES.NETWORK_MANAGER}
          path="/cadastrar-aplicacao-senna-para-criancas"
          component={SennaKids}
        />
        <RoutePrivateAccess
          accessRoles={ROLES.NETWORK_MANAGER}
          path="/editar-aplicacao-k/:id"
          component={SennaKids}
        />
        <RoutePrivateAccess
          accessRoles={ROLES.ALL}
          path="/coleta-senna-kids/:id"
          component={SennaKidsCollection}
        />
        <RoutePrivateAccess
          accessRoles={ROLES.NETWORK_MANAGER}
          path="/editar-aplicacao-m/:id"
          component={WorkWorldTool}
        />
        <RoutePrivateAccess
          accessRoles={ROLES.RESEARCHER}
          exact
          path="/aplicacoes-pesquisador"
          component={ResearcherAssessmentList}
        />
        <RoutePrivateAccess
          accessRoles={ROLES.PARTNERSHIP_COLLABORATOR}
          exact
          path="/aplicacoes-respondente"
          component={PartnershipCollaboratorAssessmentList}
        />
        <RoutePrivateAccess
          accessRoles={ROLES.RESEARCHER}
          exact
          path="/cadastrar-aplicacoes-pesquisador"
          component={ResearcherAssessment}
        />
        <RoutePrivateAccess
          accessRoles={ROLES.RESEARCHER}
          exact
          path="/editar-aplicacoes-pesquisador/:id"
          component={ResearcherAssessment}
        />
        <RoutePrivateAccess
          accessRoles={ROLES.RESEARCHER}
          exact
          path="/aplicacoes-pesquisador/visualizar/:id"
          component={ResearcherAssessmentView}
        />
        <RoutePrivateAccess
          accessRoles={ROLES.RESEARCHER}
          exact
          path="/component/researcher-instruction-and-welcome"
          component={ResearcherInstructionAndWelcome}
        />
        <RoutePrivateAccess
          exact
          accessRoles={ROLES.RESEARCHER}
          path="/parceria"
          component={PartnershipList}
        />
        <RoutePrivateAccess
          exact
          accessRoles={ROLES.RESEARCHER}
          path="/cadastrar-parceria"
          component={Partnership}
        />
        <RoutePrivateAccess
          exact
          accessRoles={ROLES.RESEARCHER}
          path="/editar-parceria/:id"
          component={Partnership}
        />
        <RoutePrivateAccess
          exact
          accessRoles={ROLES.TEACHER}
          path={[
            '/realizando-escolhas',
            '/realizando-escolhas/cadastro-escola',
            '/realizando-escolhas/cadastro-turma',
            '/realizando-escolhas/turmas',
            '/realizando-escolhas/estudantes',
            '/realizando-escolhas/cadastrar-estudante',
            '/realizando-escolhas/cadastrar-estudantes-lote',
            '/realizando-escolhas/biblioteca-relatorios',
            '/realizando-escolhas/cadastrar-instrumento',
            '/realizando-escolhas/instrumentos',
          ]}
          component={MakingChoices}
        />
        <RoutePrivateAccess
          exact
          accessRoles={ROLES.NETWORK_MANAGER}
          path="/cadastrar-unidade"
          component={UnityRegister}
        />
        <RoutePrivateAccess
          exact
          accessRoles={ROLES.NETWORK_MANAGER}
          path="/editar-unidade/:id"
          component={UnityRegister}
        />
        <RoutePrivateAccess
          exact
          accessRoles={ROLES.NETWORK_MANAGER}
          path="/unidades"
          component={UnitList}
        />
        <Route path="/cadastro/autocadastro" component={RegisterAuto} />
        <RoutePrivateAccess
          accessRoles={ROLES.NETWORK_MANAGER}
          path="/cadastrar-aplicacao-senna-para-adultos"
          component={SennaAdult}
        />
        <RoutePrivateAccess
          accessRoles={ROLES.NETWORK_MANAGER}
          path="/editar-aplicacao-senna-para-adultos/:id"
          component={SennaAdult}
        />
        <RoutePrivateAccess
          accessRoles={ROLES.NETWORK_MANAGER}
          path="/senna-adults-collection/:id"
          component={SennaAdultCollection}
        />
        <RoutePrivateAccess
          exact
          accessRoles={ROLES.RESPONDER}
          path={[
            '/senna-adults-instrument/:id',
            '/senna-adults-instrument/:id/finish',
            '/senna-adults-instrument/:id/step/:step',
          ]}
          component={SennaAdultsInstrument}
        />
        <RoutePrivateAccess
          exact
          accessRoles={ROLES.ADMIN}
          path={[
            '/admin/agentes-tecnicos-gestores',
            '/admin/agentes-tecnicos-gestores/cadastrar',
            '/admin/agentes-tecnicos-gestores/confirmar',
          ]}
          component={Administrator}
        />
        <RoutePrivateAccess
          accessRoles={ROLES.NETWORK_MANAGER}
          path="/sessoes"
          component={AccessLog}
        />
        <RoutePrivateAccess
          exact
          accessRoles={ROLES.ADMIN}
          path={['/admin/agentes-tecnicos-gestores']}
          component={Administrator}
        />
        <RoutePrivateAccess
          exact
          accessRoles={ROLES.ADMIN}
          path={['/admin-ftd/relatorio-integracao', '/admin-ftd/integracao-manual']}
          component={AdministratorFtd}
        />
        <Route component={PageNotFound} />
      </Switch>
    </Suspense>
  )
}
