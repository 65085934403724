import api from './api'
import apiGateway from './apiGateway'
import UserService from './UserService'

const initMenu = (activeIdValue, token) => {
  window.IonicaMenu.init({
    activeId: activeIdValue,
    getAccessToken: () => {
      return token
    },
    onInvalidSession: async () => {
      await UserService.doLogoutFtd()
    },
  })
}

const initIonicaMenu = async () => {
  if (UserService.isFtdUser()) {
    const activeIdValue = process.env.REACT_APP_FTD_ACTIVE_ID || ''

    await apiGateway.get('/farol-integracao-ftd/lti-launch/getExternalToken').then(value => {
      const token = value?.data?.access_token
      initMenu(activeIdValue, token)
    })
  }
}

const updateKeycloakIdOnFirstAccess = async (requestUrl, firstAccess) => {
  if (UserService.isFtdUser() && firstAccess) {
    await api.put(`${requestUrl}/ftd/update-keycloak-id`, UserService.getUserId())
  }
}

const FtdService = {
  initIonicaMenu,
  updateKeycloakIdOnFirstAccess,
}

export default FtdService
