/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useContext, useCallback } from 'react'
import PropTypes from 'prop-types'

import { Grid, CircularProgress, Typography, Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { getData } from '../../../../utils/fetchData/fetchData'
import api from '../../../../services/api'
import StepForm from './components/StepForm/StepForm'
import styles from '../PartnershipCollaboratorAssessmentForm.style'
import { useLoading, useSnackbar } from '../../../../shared/hooks'
import AuthContext from '../../../../contexts/AuthContext'
// eslint-disable-next-line max-len
import ResearcherInstructionAndWelcome from '../../../../components/ResearcherInstructionAndWelcome'
import { HttpStatus } from '../../../../utils/HttpStatus'

const useStyles = makeStyles(styles)

const StepComponent = ({
  step,
  researcherAssessment,
  history,
  getInstrument,
  closeApplication,
  assessmentPartnershipCollaborator,
}) => {
  const { showRequestError, snackbarError } = useSnackbar()
  const { showLoading, hideLoading } = useLoading()
  const [isLoading, setIsLoading] = useState(false)
  const [initialValues, setInitialValues] = useState({})
  const [questions, setQuestions] = useState([])
  const classes = useStyles()
  const [instrumentListWhole, setInstrumentListWhole] = useState([])
  const [instrumentListByQuestions, setInstrumentListByQuestions] = useState([])
  const [isInstrumentFirstStep, setIsInstrumentFirstStep] = useState(false)
  // eslint-disable-next-line no-unused-vars
  const [JUST_REFRESH_STATE, setDismissButtonClicked] = useState(false)
  const [isCardsClickable, setIsCardsClickable] = useState(true)
  const [isCompleted, setIsCompleted] = useState(false)
  const [hasNextButton, setHasNextButton] = useState(false)
  const [backToInstruction, setBackToInstruction] = useState(false)

  const typeSingle = 'single'
  const { currentUser } = useContext(AuthContext)
  const isExaminee = currentUser?.codExaminee !== undefined

  const url =
    window.location.pathname &&
    window.location.pathname.startsWith('/aplicacoes-respondente-content')
      ? '/aplicacoes-respondente-content'
      : '/aplicacoes-respondente'

  const distinct = arr => {
    return arr.filter(
      (value, index, self) =>
        index === self.findIndex(t => t.place === value.place && t.name === value.name),
    )
  }

  const needsToShowInstruction = () => {
    const currentInstrumentOnScreen = instrumentListByQuestions[0]
    const hasOnlyOneInstrumentOnScreen = instrumentListByQuestions.length === 1
    const showedInstructionInstrumentId = JSON.parse(
      localStorage.getItem('showedInstructionInstrumentId'),
    )

    return (
      hasOnlyOneInstrumentOnScreen &&
      instrumentListWhole.find(i => i.id === currentInstrumentOnScreen.id) !== undefined &&
      showedInstructionInstrumentId?.find(id => currentInstrumentOnScreen.id === id) ===
        undefined &&
      (isExaminee ? step === 0 : true)
    )
  }

  const getInstrumentListByQuestions = async questionList => {
    const arrayAuxiliar = []
    questionList.forEach(question => {
      arrayAuxiliar.push(question.notebookTool)
    })

    const currentInstrument = instrumentListWhole.find(i => i?.id === arrayAuxiliar[0]?.id)
    if (currentInstrument) {
      const isFirstInstrumentStep = questions.find(q => q.id === currentInstrument.firstItemId)
      setIsInstrumentFirstStep(isFirstInstrumentStep !== undefined)
    }

    setInstrumentListByQuestions(distinct(arrayAuxiliar))
  }

  const getNotebookToolByResearcherAssessmentId = async researcherAssessmentId => {
    setIsLoading(true)
    await api
      .get(`researcher-assessments/notebook-tool/${researcherAssessmentId}`)
      .then(resp => {
        setInstrumentListWhole(distinct(resp.data))
      })
      .catch(error => {
        snackbarError(error.response?.data?.detail)
        return error
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const rehydrateAnswerParentValue = question => {
    if (question.answerKey.type === 'TEXT') {
      return question.responseSelected.value
    }
    if (question.answerKey.type === 'CHECK_BOX') {
      return question.responsesSelecteds
    }
    return question.responseSelected.id
  }

  const getQuestions = useCallback(async () => {
    if (
      researcherAssessment &&
      step !== undefined &&
      step < assessmentPartnershipCollaborator.steps
    ) {
      const params = {
        examineeId: currentUser.codExaminee !== undefined ? currentUser.id : '',
        researcherAssessmentId: researcherAssessment.id,
        step: step + 1,
        notebookId: assessmentPartnershipCollaborator.notebook.id,
      }

      const request = {
        url: 'researcher-assessments/questions',
        options: { params },
        showRequestError,
        setIsLoading,
      }

      const data = await getData(request)

      const obj = {}
      data.forEach(q => {
        obj[q.id] = {
          parentValue: q.responseSelected ? rehydrateAnswerParentValue(q) : null,
          childValue: q.responseChildrenSelected ? q.responseChildrenSelected.id : null,
        }
      })
      setInitialValues(obj)
      setQuestions(data)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (questions.length > 0 && instrumentListWhole.length > 0) {
      getInstrumentListByQuestions(questions)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questions, instrumentListWhole])

  useEffect(() => {
    if (step === assessmentPartnershipCollaborator.steps) {
      localStorage.removeItem('showedInstructionInstrumentId')
    }
    getQuestions()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step])

  useEffect(() => {
    getNotebookToolByResearcherAssessmentId(researcherAssessment.id)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [researcherAssessment])

  const dimissInstruction = async instrumentId => {
    const showedInstructionInstrumentIdStr = localStorage.getItem('showedInstructionInstrumentId')
    if (showedInstructionInstrumentIdStr !== null) {
      localStorage.setItem(
        'showedInstructionInstrumentId',
        JSON.stringify([...JSON.parse(showedInstructionInstrumentIdStr), instrumentId]),
      )
    } else {
      localStorage.setItem('showedInstructionInstrumentId', JSON.stringify([instrumentId]))
    }
    setDismissButtonClicked(true)
    setBackToInstruction(false)
  }

  const handleChangeChildren = async (question, value, valueChildren) => {
    setIsCardsClickable(false)
    const response = await api.post(`researcher-assessment-response-records`, {
      researcherAssessmentId: researcherAssessment.id,
      notebookItemId: question.id,
      answerItemId: value,
      examineeId: currentUser.codExaminee !== undefined ? currentUser.id : '',
      answerChildrenItemId: parseInt(valueChildren),
      initialDateTimeSessionTemp: parseInt(localStorage.getItem('initialDateTimeSessionTemp')),
    })

    if (response.status !== HttpStatus.CREATED) {
      snackbarError(response?.data?.detail)
      alert('Error!')
    }
    setIsCardsClickable(true)
    return response.status
  }

  async function next() {
    setIsLoading(true)
    const examineeId = currentUser.codExaminee !== undefined ? currentUser.id : ''
    const _url =
      examineeId !== ''
        ? // eslint-disable-next-line max-len
          `researcher-assessment-partnership-collaborator/update-status-step/${researcherAssessment.id}/${examineeId}/continue`
        : // eslint-disable-next-line max-len
          `researcher-assessment-partnership-collaborator/update-status-step/${researcherAssessment.id}/continue`
    const response = await api.put(_url)

    try {
      if (response.status === HttpStatus.OK) {
        setIsLoading(false)
        await getInstrument(researcherAssessment.id)
      } else {
        snackbarError(response?.data?.detail)
      }
    } catch (error) {
      snackbarError(response?.data?.detail)
    }
    setIsLoading(false)
  }

  const handleBack = async () => {
    if (!isInstrumentFirstStep) {
      setIsLoading(true)
      const examineeId = currentUser.codExaminee !== undefined ? currentUser.id : ''
      const _url =
        examineeId !== ''
          ? // eslint-disable-next-line max-len
            `researcher-assessment-partnership-collaborator/update-status-step/${researcherAssessment.id}/${examineeId}/back`
          : // eslint-disable-next-line max-len
            `researcher-assessment-partnership-collaborator/update-status-step/${researcherAssessment.id}/back`
      await api
        .put(_url)
        .then()
        .catch(error => {
          snackbarError(error.response?.data?.detail)
          return error
        })
        .finally(() => {
          setIsLoading(false)
          getInstrument(researcherAssessment.id)
          window.scrollTo(0, 0, 'smooth')
        })
    } else {
      const showedInstructionInstrumentIdStr = localStorage.getItem('showedInstructionInstrumentId')
      const listOfInstrumentsWithShowedInstructions = [
        ...JSON.parse(showedInstructionInstrumentIdStr),
      ]
      const filteredListWithoutInstrumentId = listOfInstrumentsWithShowedInstructions.filter(
        id => id !== instrumentListByQuestions[0].id,
      )
      localStorage.setItem(
        'showedInstructionInstrumentId',
        JSON.stringify([...filteredListWithoutInstrumentId]),
      )

      setBackToInstruction(true)
    }
  }

  const onPressReport = () => {
    history.push('/biblioteca-relatorios')
  }

  const finishedAplication = async researcherAssessmentId => {
    if (researcherAssessmentId) {
      setIsLoading(true)
      const examineeId = currentUser.codExaminee !== undefined ? currentUser.id : ''
      const _url =
        examineeId !== ''
          ? // eslint-disable-next-line max-len
            `researcher-assessment-partnership-collaborator/finished/${researcherAssessmentId}/${examineeId}`
          : `researcher-assessment-partnership-collaborator/finished/${researcherAssessmentId}`
      try {
        const status = await api.put(_url)
        setIsLoading(false)
        return status?.data
      } catch (error) {
        const { detail } = error.response.data
        showRequestError(detail)
        setIsLoading(false)
      }
    }
    setIsLoading(false)
    return ''
  }

  const handleGoToMenuAplication = async () => {
    history.push(url)
  }

  const isAssessmentFullyCompleted = useCallback(async () => {
    setIsLoading(true)
    await api
      .get(`researcher-assessment-response-records/is-completed/`, {
        params: {
          assessmentId: researcherAssessment.id,
          examineeId: currentUser.codExaminee !== undefined ? currentUser.id : '',
          totalAssessmentSteps: assessmentPartnershipCollaborator.steps,
        },
      })
      .then(response => {
        setIsCompleted(response.data)
      })
      .catch(error => {
        snackbarError(error.response?.data?.detail)
        return error
      })

    setIsLoading(false)
  }, [
    assessmentPartnershipCollaborator.steps,
    currentUser.codExaminee,
    currentUser.id,
    researcherAssessment.id,
    snackbarError,
  ])

  const handleNextSubmit = async (values, setSubmitting) => {
    const answeredQuestions = questions.filter(q => {
      const { type } = q.answerKey
      if (type === 'PUPPETS') {
        if (q.answerKey.items[0]?.answerKeyChildren) {
          return values[q.id]?.parentValue != null && values[q.id]?.childValue != null
        }
        return values[q.id]?.parentValue != null
      }
      return (
        values[q.id]?.parentValue != null ||
        (type === 'CHECK_BOX' &&
          values[q.id].parentValue &&
          values[q.id].parentValue?.length !== 0) ||
        (type === 'TEXT' && values[q.id].parentValue !== '')
      )
    })
    if (answeredQuestions.length === questions.length) {
      if (step < assessmentPartnershipCollaborator.steps - 1) {
        await next(values)
        setSubmitting(false)
        await isAssessmentFullyCompleted()
        setIsLoading(false)
      } else {
        await next(values)
        const status = await finishedAplication(researcherAssessment.id)
        if (status === 'WITHOUT_ANSWER') {
          snackbarError(
            // eslint-disable-next-line max-len
            'Ops! Ainda faltam perguntas a serem respondidas. Você será direcionado para responder as perguntas faltantes.',
          )
          setTimeout(async () => {
            await getInstrument(researcherAssessment.id)
            setIsLoading(false)
          }, 5000)
        } else {
          setSubmitting(false)
          localStorage.removeItem('initialDateTimeSessionTemp')
          await isAssessmentFullyCompleted()
          setIsLoading(false)
        }
      }
    }
  }

  const handleSubmit = async (values, { setSubmitting }, clicked) => {
    if (!hasNextButton || (hasNextButton && clicked)) {
      const audios = Array.from(document.getElementsByTagName('audio'))
      const videos = Array.from(document.getElementsByTagName('video'))

      const audioAndVideos = videos.concat(audios)

      audioAndVideos.forEach(audioOrVideo => {
        // eslint-disable-next-line no-param-reassign
        audioOrVideo.hasStarted = true
        audioOrVideo.pause()
      })

      await handleNextSubmit(values, setSubmitting)
    }
  }

  const handleChange = async (question, option, values) => {
    if (!isExaminee) {
      setIsCardsClickable(false)

      let response
      if (question.answerKey.type === 'PUPPETS') {
        response = await api.post(`researcher-assessment-response-records`, {
          researcherAssessmentId: researcherAssessment.id,
          notebookItemId: question.id,
          answerItemId: parseInt(option.id),
          answerItemTextField: question.answerKey.type === 'TEXT' ? option : undefined,
          examineeId: currentUser.codExaminee !== undefined ? currentUser.id : '',
          initialDateTimeSessionTemp: parseInt(localStorage.getItem('initialDateTimeSessionTemp')),
        })

        if (response.status !== 201) {
          snackbarError(response?.data?.detail)
        } else if (!option.answerKeyChildren && question.answerKey.type !== 'TEXT') {
          await handleSubmit(values, { setSubmitting: () => {} })
        }
        setIsCardsClickable(true)
        setIsLoading(false)
      }
    }
    setIsLoading(false)
  }

  const handleButtonNext = async valuesParam => {
    const answeredList = []
    const answeredListCheckbox = []
    questions.forEach(q => {
      if (
        q.answerKey.type !== 'TEXT' &&
        q.answerKey.type !== 'CHECK_BOX' &&
        q.answerKey.type !== 'PUPPETS'
      ) {
        const answeredObj = valuesParam[q.id]
        const obj = {
          researcherAssessmentId: researcherAssessment.id,
          notebookItemId: q.id,
          answerItemId: answeredObj?.parentValue,
          examineeId: currentUser.codExaminee !== undefined ? currentUser.id : '',
          initialDateTimeSessionTemp: parseInt(localStorage.getItem('initialDateTimeSessionTemp')),
        }
        answeredList.push(obj)
      } else if (q.answerKey.type === 'CHECK_BOX') {
        const answeredObj = valuesParam[q.id]
        const obj = {
          researcherAssessmentId: researcherAssessment.id,
          notebookItemId: q.id,
          answerItemId: 0,
          answerItemIds: answeredObj?.parentValue,
          examineeId: currentUser.codExaminee !== undefined ? currentUser.id : '',
          initialDateTimeSessionTemp: parseInt(localStorage.getItem('initialDateTimeSessionTemp')),
        }
        answeredListCheckbox.push(obj)
      }
    })
    showLoading()
    if (answeredList.length > 0 || answeredListCheckbox.length > 0) {
      const response = await api.post(`researcher-assessment-response-records/batch-save`, {
        responseRecordRequestList: answeredList,
      })

      const responseCheckbox = await api.post(
        `researcher-assessment-response-records/multipleitems`,
        {
          responseRecordRequestList: answeredListCheckbox,
        },
      )

      if (response?.data || responseCheckbox?.data) {
        await handleSubmit(valuesParam, { setSubmitting: () => {} }, true)
      }
    } else {
      await handleSubmit(valuesParam, { setSubmitting: () => {} }, true)
    }
    hideLoading()
  }

  useEffect(() => {
    const checkComplete = async () => {
      await isAssessmentFullyCompleted()
    }
    setTimeout(async () => {
      checkComplete()
    }, 1500)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assessmentPartnershipCollaborator])

  useEffect(() => {
    if (step === assessmentPartnershipCollaborator.steps && isCompleted) {
      if (researcherAssessment.status === 'STARTED') {
        finishedAplication(researcherAssessment.id)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [researcherAssessment, step, isCompleted])

  useEffect(() => {
    setHasNextButton(assessmentPartnershipCollaborator.researcherAssessment.showNextButton)
  }, [assessmentPartnershipCollaborator])

  return (
    <>
      {isLoading && (
        <Grid container spacing={3} className={classes.root}>
          <CircularProgress style={{ marginLeft: 'auto', marginRight: 'auto' }} />
        </Grid>
      )}

      {!isLoading && step === assessmentPartnershipCollaborator.steps && isCompleted ? (
        <Grid container spacing={6} align="center">
          <Grid item xs={12}>
            <Typography className={classes.titleStep}>Muito bom! Etapa concluída.</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography>Você completou todas as fases. Agradecemos a sua participação!</Typography>
          </Grid>
          <Grid container item xs={8} className={classes.buttons}>
            {!isExaminee ? (
              <Grid item xs={3}>
                <Button
                  variant="outlined"
                  onClick={() => onPressReport(researcherAssessment, typeSingle)}
                >
                  Devolutiva
                </Button>
              </Grid>
            ) : null}
            <Grid item xs={3}>
              <Button color="secondary" onClick={() => handleGoToMenuAplication()}>
                VOLTAR PARA AVALIAÇÕES
              </Button>
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <>
          {needsToShowInstruction() || backToInstruction ? (
            <ResearcherInstructionAndWelcome
              id={instrumentListByQuestions[0].id}
              title={isExaminee ? 'Senna para crianças' : instrumentListByQuestions[0].name}
              description={isExaminee ? '' : instrumentListByQuestions[0].instruction}
              attachment={instrumentListByQuestions[0].attachments[0]}
              dimissInstruction={dimissInstruction}
              isWelcome={false}
              onClose={closeApplication}
            />
          ) : (
            <Formik
              initialValues={{ ...initialValues }}
              enableReinitialize
              onSubmit={handleSubmit}
              validationSchema={Yup.object().shape({})}
              render={formikProps => (
                <Form onSubmit={formikProps.handleSubmit}>
                  <StepForm
                    titleStep="Avaliação do Pesquisador"
                    questions={questions}
                    // eslint-disable-next-line no-template-curly-in-string
                    stage={`QUESTIONÁRIO ${step + 1}`}
                    {...formikProps}
                    hasNextButton={hasNextButton}
                    handleButtonNext={handleButtonNext}
                    handleChange={handleChange}
                    handleChangeChildren={handleChangeChildren}
                    handleSubmit={handleSubmit}
                    handleBack={handleBack}
                    closeApplication={closeApplication}
                    researcherAssessment={researcherAssessment}
                    assessmentPartnershipCollaborator={assessmentPartnershipCollaborator}
                    isCardsClickable={isCardsClickable}
                    setIsCardsClickable={setIsCardsClickable}
                  />
                </Form>
              )}
            />
          )}
        </>
      )}
    </>
  )
}

StepComponent.propTypes = {
  instrument: PropTypes.object.isRequired,
}

export default StepComponent
