import { useEffect, useState, useContext } from 'react'

import { PROFILES } from '../../../shared'
import { useSnackbar } from '../../../shared/hooks'
import { getData } from '../../../utils/fetchData/fetchData'
import AuthContext from '../../../contexts/AuthContext'

import { pageSizeOptions } from '../constants'

const useGetClassrooms = () => {
  const [data, setData] = useState({ content: [] })
  const [updateTable, setUpdateTable] = useState(false)
  const [filterForm, setFilterForm] = useState({
    page: 0,
    size: pageSizeOptions[0],
    schoolIds: null,
    classroomIds: null,
    teachingNetworkIds: null,
    status: null,
    gradeIds: null,
    sort: null,
  })
  const [isLoading, setLoading] = useState(false)

  const { snackbarError } = useSnackbar()
  const { currentUser } = useContext(AuthContext)

  const getClassroomsByFilters = async () => {
    const sort = filterForm.sort ? Object.values(filterForm.sort).join(',') : undefined
    const { id } = currentUser.teachingNetwork
    const params = {
      profileId: PROFILES.NETWORK_MANAGER.id,
      userTeachingNetworkId: id,
      page: filterForm.page,
      size: filterForm.size,
      schoolIds: filterForm.schoolIds || undefined,
      classroomIds: filterForm.classroomIds || undefined,
      teachingNetworkIds: filterForm.teachingNetworkIds || undefined,
      status: filterForm.status,
      gradeIds: filterForm.gradeIds || undefined,
      sort,
    }

    const requestParams = {
      url: 'program/filter/classroom',
      options: { params },
      snackbarError,
      setLoading,
    }

    const responseData = await getData(requestParams)
    const classroomsList = responseData?.content?.map(classroom => {
      const hasLinkedPrograms = Array.isArray(classroom.programs)
      const linkedPrograms = hasLinkedPrograms
        ? classroom.programs.map(program => ({
            id: Number(program.id),
            name: String(program.name),
          }))
        : []

      return {
        id: classroom.id,
        schoolName: classroom.school.name,
        classroomName: classroom.name,
        gradeName: classroom.grade.name,
        gradeOrder: classroom.grade.ordem,
        schoolShiftName: classroom.schoolShift.name,
        isProgramLinked: classroom.programLinked,
        linkedPrograms,
        haveAnswers: classroom.haveAnswers,
        excessJustifications: classroom.excessJustifications,
        schoolYear: classroom.schoolYear,
      }
    })

    setData({ ...responseData, content: classroomsList })
    setUpdateTable(false)
  }

  useEffect(() => {
    if (updateTable) {
      getClassroomsByFilters()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateTable])

  useEffect(() => {
    getClassroomsByFilters()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    isLoading,
    data,
    setFilterForm,
    setUpdateTable,
    filterForm,
    getClassroomsByFilters,
  }
}

export { useGetClassrooms }
