import { useMediaQuery } from 'react-responsive'

export function useResponsiveInfo() {
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' })
  const isTablet = useMediaQuery({ query: '(min-width: 768px) and (max-width: 1224px)' })
  const isDesktop = useMediaQuery({ query: '(min-width: 1224px)' })
  const isLargeDesktop = useMediaQuery({ query: '(min-width: 1500px)' })
  const isSmallDesktop = useMediaQuery({ query: '(min-width: 1224px) and (max-width: 1500px)' })
  const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
  const isLandscape = useMediaQuery({ query: '(orientation: landscape)' })

  const getResponsivePadding = () => {
    if (isMobile) {
      return 15
    }
    if (isTablet) {
      return 20
    }

    return 0
  }

  return {
    isMobile,
    isTablet,
    isDesktop,
    isPortrait,
    isLandscape,
    isLargeDesktop,
    isSmallDesktop,
    getResponsivePadding,
  }
}
