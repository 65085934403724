import React from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import Slide from '@material-ui/core/Slide'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/styles'
import { Grid } from '@material-ui/core'
import styles from './ModalDialogPopUp.style'

const useStyles = makeStyles(styles)

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

export default function ModalDialogPopUp({
  id,
  text,
  textConfirm,
  textClose,
  title,
  open,
  cancel,
  handleConfirm,
  handleClose,
}) {
  const classes = useStyles()

  return (
    <div>
      <Dialog
        id={id}
        open={open}
        TransitionComponent={Transition}
        keepMounted
        PaperProps={{
          style: {
            borderRadius: '16px',
          },
        }}
        onClose={cancel}
        maxWidth={false}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent style={{ padding: 0 }} className={classes.container}>
          <DialogContent className={classes.dialog}>
            <Typography className={classes.title} style={{ fontWeight: 'bold' }}>
              {title}
            </Typography>
            <DialogContentText id="alert-dialog-slide-description">
              <Typography className={classes.text} component="div">
                {text}
              </Typography>
            </DialogContentText>
          </DialogContent>
          <DialogActions className={classes.buttonContainer}>
            <Grid container direction="row" justifyContent="space-around">
              <Grid style={{ display: 'flex', justifyContent: 'center' }} item xs={6}>
                <Button
                  color="white"
                  size="large"
                  style={{
                    background: 'transparent',
                    border: '2px solid #003C78',
                    color: '#003C78',
                    textTransform: 'none',
                    fontWeight: '600',
                  }}
                  onClick={handleClose}
                >
                  {textClose}
                </Button>
              </Grid>
              <Grid style={{ display: 'flex', justifyContent: 'center' }} item xs={6}>
                <Button
                  color="primary"
                  size="large"
                  style={{ textTransform: 'none', fontWeight: '400', padding: '0 25px' }}
                  onClick={handleConfirm}
                >
                  {textConfirm}
                </Button>
              </Grid>
            </Grid>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </div>
  )
}

ModalDialogPopUp.propTypes = {
  handleClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
}
