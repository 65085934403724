/* eslint-disable no-restricted-globals */
/* eslint-disable no-console */
import React, { useState, useEffect, useContext } from 'react'
import PropTypes from 'prop-types'

import { Grid, CircularProgress, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

import { useResponsiveInfo, useSnackbar } from '../../shared/hooks'
import api from '../../services/api'
import styles from './PartnershipCollaboratorAssessment.style'
import PartnershipCollaboratorAssessmentForm from './PartnertshipCollaboratorAssessmentForm'
import ResearcherInstructionAndWelcome from '../../components/ResearcherInstructionAndWelcome'
import ModalDialogDefault from '../../components/ModalDialogDefault'
import AuthContext from '../../contexts/AuthContext'
import { Breadcrumbs } from '../../shared'

const useStyles = makeStyles(styles)

const PartnershipCollaboratorAssessment = ({ history, match }) => {
  const { showRequestError, snackbarError } = useSnackbar()
  const [isLoading, setIsLoading] = useState(false)
  const [firstAccess, setFirstAccess] = useState(true)
  const [researcherAssessment, setResearcherAssessment] = useState()
  const [assessmentPartnershipCollaborator, setAssessmentPartnershipCollaborator] = useState()
  const [showCloseModalDialog, setShowCloseModalDialog] = useState(false)
  const { currentUser } = useContext(AuthContext)
  const { isDesktop, isTablet, getResponsivePadding } = useResponsiveInfo()
  const classes = useStyles()
  const isExaminee = currentUser?.codExaminee !== undefined
  const isiPhone = navigator.userAgent.toLowerCase().includes('iphone')

  const mapTestTypeToNumericValue = testType => {
    switch (testType) {
      case 'ONE_FOR_SCREEN':
        return 1
      case 'THREE_FOR_SCREEN':
        return 3
      case 'FIVE_FOR_SCREEN':
        return 5
      case 'TEN_FOR_SCREEN':
        return 10
      case 'FIFTEEN_FOR_SCREEN':
        return 15
      case 'TWENTY_FOR_SCREEN':
        return 20
      default:
        return null
    }
  }

  const getInstrument = async researcherAssessmentId => {
    if (researcherAssessmentId) {
      setIsLoading(true)

      const finalData = {}
      const researcherAssessmentPartnershipCollab = await api.get(
        `researcher-assessment-partnership-collaborator/${researcherAssessmentId}?examineeId=${
          currentUser.codExaminee !== undefined ? currentUser.id : ''
        }`,
      )

      if (researcherAssessmentPartnershipCollab.status === 200) {
        const { data } = researcherAssessmentPartnershipCollab
        if (data.status === 'STARTED') {
          setFirstAccess(false)
        }
        finalData.researcherAssessment = data.researcherAssessment
        finalData.assesmentPartnershipCollaborator = data

        const notebookItem = Array.from(
          finalData.assesmentPartnershipCollaborator.notebook.notebookItemList,
        )[0]
        finalData.researcherAssessment.toolName = notebookItem.notebookTool.name
        finalData.researcherAssessment.toolInstruction = notebookItem.notebookTool.instruction

        const params = { assessmentId: researcherAssessmentId, notebookId: data.notebook.id }
        const notebook = await api.get('researcher-assessment-notebook', { params })

        if (notebook.status === 200) {
          finalData.assesmentPartnershipCollaborator.steps = notebook.data.steps
          finalData.assesmentPartnershipCollaborator.testType = notebook.data.testType

          finalData.assesmentPartnershipCollaborator.numTestType = mapTestTypeToNumericValue(
            finalData.assesmentPartnershipCollaborator.testType,
          )
          finalData.assesmentPartnershipCollaborator.qtdQuestionsPerPage =
            finalData.assesmentPartnershipCollaborator.notebook.notebookItemList.length /
            finalData.assesmentPartnershipCollaborator.numTestType

          setResearcherAssessment(finalData.researcherAssessment)
          setAssessmentPartnershipCollaborator(finalData.assesmentPartnershipCollaborator)
        } else {
          const { detail } = notebook.data
          showRequestError(detail)
        }
      } else {
        const { detail } = researcherAssessmentPartnershipCollab.data
        showRequestError(detail)
      }
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (!isLoading && !isiPhone && isExaminee) {
      document
        .getElementById('fullScreen')
        .requestFullscreen()
        .then(() => {
          if (isExaminee) {
            screen.orientation.lock('landscape')
          }
        })
        .catch(() => {
          console.log('FullScreen')
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, isExaminee])

  useEffect(() => {
    if (currentUser) {
      getInstrument(match.params.id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match.params.id, currentUser, showRequestError])

  const handleInit = async () => {
    setIsLoading(true)
    await api
      .put(
        `researcher-assessment-partnership-collaborator/started/${match.params.id}?examineeId=${
          currentUser.codExaminee !== undefined ? currentUser.id : ''
        }`,
      )
      .catch(error => {
        snackbarError(error.response?.data?.detail)
      })
      .finally(() => setIsLoading(false))

    setFirstAccess(false)
  }

  return (
    <>
      {isLoading ? (
        <Grid container spacing={3} className={classes.root}>
          <CircularProgress style={{ marginLeft: 'auto', marginRight: 'auto' }} />
        </Grid>
      ) : (
        <Grid id="fullScreen" className={classes.root} style={{ marginLeft: 20, marginRight: 20 }}>
          {firstAccess ? (
            <Grid style={{ width: '100%', height: '100%' }}>
              <Grid
                style={{
                  paddingLeft: getResponsivePadding(),
                  paddingRight: getResponsivePadding(),
                }}
              >
                <Breadcrumbs
                  items={[
                    { name: 'Início', path: '/' },
                    { name: 'Avaliações', path: '/aplicacoes-respondente' },
                    { name: researcherAssessment?.name },
                  ]}
                />
              </Grid>
              {(isDesktop || isTablet) && (
                <Grid>
                  <Typography
                    style={{
                      color: '#003C78',
                      fontSize: 32,
                      fontWeight: 700,
                      marginBottom: 20,
                      paddingLeft: getResponsivePadding(),
                      paddingRight: getResponsivePadding(),
                    }}
                  >
                    {researcherAssessment?.name}
                  </Typography>
                </Grid>
              )}
              <ResearcherInstructionAndWelcome
                id={researcherAssessment?.id}
                title="Seja bem-vindo(a) à avaliação!"
                description={researcherAssessment?.welcomeMessage}
                attachment={researcherAssessment?.attachment}
                isWelcome
                dimissInstruction={() => {
                  handleInit()
                }}
              />
              <ModalDialogDefault
                id="modalExitAssessment"
                open={showCloseModalDialog}
                title="Atenção!"
                // eslint-disable-next-line max-len
                text="Ao sair da página, você vai parar de preencher o instrumento e <b>seu progresso será salvo.</b><br/>Deseja, mesmo assim, sair da página? "
                textConfirm="Sair da página"
                textClose="Voltar"
                backgroundColor="#003d71"
                handleClose={() => {
                  setShowCloseModalDialog(false)
                  document.getElementById('fullScreen').requestFullscreen()
                  if (isExaminee) {
                    // eslint-disable-next-line no-restricted-globals
                    screen.orientation.unlock()
                  }
                }}
                handleConfirm={() => {
                  localStorage.removeItem('initialDateTimeSessionTemp')
                  history.push('/dashboard')
                }}
              />
            </Grid>
          ) : (
            <PartnershipCollaboratorAssessmentForm
              assessmentPartnershipCollaborator={assessmentPartnershipCollaborator}
              researcherAssessment={researcherAssessment}
              history={history}
              getInstrument={getInstrument}
            />
          )}
        </Grid>
      )}
    </>
  )
}
PartnershipCollaboratorAssessment.propTypes = {
  history: PropTypes.object.isRequired,
}

export default PartnershipCollaboratorAssessment
