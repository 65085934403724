/* eslint-disable react/no-danger */
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import ErrorIcon from '@material-ui/icons/Error'
import InfoIcon from '@material-ui/icons/Info'
import CloseIcon from '@material-ui/icons/Close'
import { green } from '@material-ui/core/colors'
import IconButton from '@material-ui/core/IconButton'
import Snackbar from '@material-ui/core/Snackbar'
import SnackbarContent from '@material-ui/core/SnackbarContent'
import WarningIcon from '@material-ui/icons/Warning'
import { makeStyles } from '@material-ui/core/styles'
import { Button } from '@material-ui/core'
import ReportModal from '../ReportModal/ReportModal'
import { getSanitizedHtmlString } from '../../utils/SanitizeHtml'

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
}

const useStyles1 = makeStyles(theme => ({
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: theme.palette.error.dark,
    flexDirection: 'row',
    flexWrap: 'nowrap',
  },
  info: {
    backgroundColor: theme.palette.primary.main,
  },
  warning: {
    backgroundColor: '#FFCA00',
    color: '#003C78',
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
  button: {
    color: 'white',
    border: '1px solid white',
    backgroundColor: 'transparent',
    textTransform: 'none',
    fontSize: '14px',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
}))

const CustomSnackbar = ({ variant, open, handleClose, autoHideDuration, message, buttonHelp }) => {
  const classes = useStyles1()
  const Icon = variantIcon[variant]
  const isImportacaoDados = window.location.pathname === '/importar-dados-acompanhamento'
  const [openHelpModal, setOpenOpenHelpModal] = useState(false)

  const handleOpenModal = () => {
    setOpenOpenHelpModal(true)
    handleClose()
  }

  const actions = [
    <Button onClick={handleOpenModal} className={classes.button} size="small">
      Fale com o IAS
    </Button>,
    <IconButton key="close" aria-label="close" color="inherit" onClick={handleClose}>
      <CloseIcon className={classes.icon} />
    </IconButton>,
  ]

  return (
    <div>
      <ReportModal open={openHelpModal} setOpen={setOpenOpenHelpModal} />
      <Snackbar
        open={open}
        autoHideDuration={isImportacaoDados ? 20000 : autoHideDuration}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        ClickAwayListenerProps={{ onClickAway: () => null }}
      >
        <SnackbarContent
          className={classes[variant]}
          message={
            <span className={classes.message}>
              <Icon className={clsx(classes.icon, classes.iconVariant)} />
              <p dangerouslySetInnerHTML={{ __html: getSanitizedHtmlString(message) }} />
            </span>
          }
          action={actions[buttonHelp ? 0 : 1]}
        />
      </Snackbar>
    </div>
  )
}

CustomSnackbar.propTypes = {
  autoHideDuration: PropTypes.number,
  buttonHelp: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
  message: PropTypes.string,
  open: PropTypes.bool.isRequired,
  variant: PropTypes.oneOf(['error', 'info', 'success', 'warning']),
}

CustomSnackbar.defaultProps = {
  autoHideDuration: 30000,
  buttonHelp: false,
  message: '',
  variant: 'success',
}

export default CustomSnackbar
