import { useState } from 'react'

import { useSnackbar } from '../../../shared/hooks'
import { postData } from '../../../utils/fetchData/fetchData'

const useLinkProgramsService = () => {
  const { snackbarSuccess, snackbarError } = useSnackbar()
  const { isLoading, setLoading } = useState(false)

  const linkProgramsService = classroomsToLink => {
    // Avoid request without program IDs.
    if (classroomsToLink[0].programIds.length === 0) {
      return null
    }

    return postData({
      url: 'program/classroom',
      data: classroomsToLink,
      snackbarSuccess: () => snackbarSuccess('Programa(s) vinculado(s) com sucesso!'),
      snackbarError: error => {
        let errorMessage = 'Ocorreu um erro ao vincular os programas.'

        if (error?.response?.data?.detail) {
          errorMessage = error.response.data.detail
        } else if (error?.response?.data?.message) {
          errorMessage = error.response.data.message
        } else if (error?.message) {
          errorMessage = error.message
        } else {
          errorMessage = 'Erro desconhecido. Por favor, tente novamente.'
        }

        snackbarError(errorMessage)
      },
      setIsLoading: setLoading,
    })
  }

  return {
    isLoading,
    linkProgramsService,
  }
}

export { useLinkProgramsService }
